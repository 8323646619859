<template>
  <div class="container h-100 bg-first p-3">
    <b-card>
      <div>
        <h3 class="m-0">
          รายการสลากฯ ของฉัน
        </h3>
        <small>คุณจำหน่ายสลากได้ทั้งหมด
          <span class="text-danger">- ใบ</span> ในการจ่ายเงิน
          เพื่อเป็นเจ้าของรายการสลาก ฯ ที่ท่านสั่งซื้อ</small>
      </div>

      <div class="mt-2">
        <h4>
          เฮงมากมาก ค้าสลาก มีทั้งหมด
          <span class="text-danger">{{ exlotto.length }} รายการ</span>
        </h4>
      </div>

      <div style="overflow-x: auto">
        <table class="table">
          <thead>
            <tr>
              <th>เล่มสลาก</th>
              <th>สถานะ</th>
              <th>จำนวน</th>
              <th>Null</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in exlotto"
              :key="item._id"
            >
              <td>
                {{ item.lottonumber }}
                <br>
                {{ item.roundth }}
                <br>
                งวดที่ 1
                <br>
                ชุดที่ {{ item.series_no }}
                <br>
                เล่มที่ {{ item.set_no }}
                <img
                  src="img/lotto-2.png"
                  width="120"
                  alt="lottery"
                >
                <!-- <h4 class="m-0">123456</h4> -->
              </td>
              <td>
                <span class="btn btn-success">พร้อมขาย</span>
              </td>
              <td>
                <h6 class="mb-0 font-weight-bolder">
                  100 ใบ
                </h6>
                <small>งวด {{ item.roundth }}</small>
              </td>
              <td>
                <h6 class="mb-0">
                  -
                </h6>
                <a href="#!"><small>-</small></a>
              </td>
              <td>
                <h6 class="mb-0">
                  -
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <b-pagination
            v-model="currentPage"
            hide-goto-end-buttons
            :total-rows="rows"
          />
        </div>
      </div>

      <div class="mt-2">
        <h4 class="text-divider">
          <span class="text-danger text-center">ขายไปแล้ว - ใบ</span>
        </h4>
      </div>

      <div class="mt-4">
        <h4 class="text-divider">
          <span class="text-danger text-center">รวมทั้งหมด - บาท</span>
        </h4>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BPagination } from 'bootstrap-vue'

export default {
  name: 'Order',
  components: {
    BCard,
    BPagination,
  },
  data() {
    return {
      number: 1,
      selectNum: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
      ],
      cart: [],
      exlotto: [],
    }
  },
  mounted() {
    this.cart = JSON.parse(localStorage.getItem('agentCart'))
    this.getexlotto()
  },
  methods: {
    getexlotto() {
      this.cart.forEach(item => {
        this.$axios
          .get(`/api/lottery/getonelotterybyset/${item}`)
          .then(ress => {
            this.exlotto.push(ress.data)
          })
      })
    },
    minus() {
      if (this.number > 1) {
        // eslint-disable-next-line no-plusplus
        this.number--
      }
    },
  },

  head() {
    return {
      title: 'รายการสั่งซื้อสลากฯ ทั้งหมดของคุณ',
    }
  },
}
</script>

<style scoped>
.table {
  border-spacing: 0 15px;
  border-collapse: separate;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  scrollbar-width: none;
}
table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
  vertical-align: middle;
  border: none;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
th {
  text-align: inherit;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.table tbody tr {
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  border-radius: 5px;
}
.table tbody tr td:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.table tbody tr td {
  background: #fff;
}
.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
  vertical-align: middle;
  border: none;
}
</style>
